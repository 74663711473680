import { Col, Row } from "react-bootstrap";
import GradPic from "../../images/IMG_5830.PNG";
import ChiExp from "../../images/IMG_5461.JPG";
import Pic1 from "../../images/pic1-1.png";
import Pic2 from "../../images/pic2-1.png";
import Pic4 from "../../images/pic3-1.png";
import Pic5 from "../../images/pic5-1.png";
import "./About.css";

export default function Home() {
	return (
		<Row className='body-app-content'>
			<Col className='sidebar-l-about' md={4} sm={12}>
				<div>
					<h3>ARTIST STATEMENT</h3>
					<p>
						In my practice, I strive to blend the raw discomforts of physical
						existence with a dreamy desire for an unreachable immateriality. I
						define my visual language through abstraction and aim to push the
						boundaries of figuration. I explore the bizarre and disorienting
						nature of the modern-day human experience in a humorous and colorful
						way in an attempt to harmonize conflicting and contrary notions.
					</p>
					<p>
						Energy and aggression are something that is prominent in my
						pictorial language. When painting, I am not bothered by
						irregularities and try to create an active application that pushes
						marks. I like to explore color relationships and push the boundaries
						of color to an almost psychedelic extent. I aim to capture the
						essence of something that cannot easily be defined by words. For
						this reason, a lot of my paintings draw from dream-scapes and the
						abstract imagination. The use of color, abstraction, and line are
						the main ways in which I express this energy.
					</p>
					<p>
						My art serves as a vehicle for permanent self-questioning; and in
						it, I try to unravel predetermined notions and make sense of the
						messiness. I like to challenge myself to bring together things that
						seemingly do not belong. While showcasing the duality of life, I
						ultimately aim to move beyond themes of strife into ideas of
						transformation.
					</p>

					<h3>EDUCATION</h3>

					<h2>School of the Art Institute of Chicago, Chicago, Illinois </h2>
					<p>August 2019-Ongoing Expected Graduation -May 2023 with a BFA </p>
					<h2>Nerinx Hall High School, Webster Groves, Missouri </h2>
					<p>
						August 2015 - May 2019 National Honors Society Student Advisory
						Board{" "}
					</p>
				</div>
			</Col>
			<Col className='contenido-about' md={4} sm={12}>
				<img className='about-img' src={Pic5} alt='Graduation day' />
				<div style={{ display: "flex", justifyContent: "space-evenly" }}>
					<div>
						<img style={{ width: "9em" }} src={Pic1} alt='Graduation day' />
					</div>
					<div>
						<img style={{ width: "9em" }} src={Pic2} alt='Graduation day' />
					</div>
				</div>
				<div>
					<img
						style={{ width: "14em", margin: "1em 0" }}
						src={Pic4}
						alt='Graduation day'
					/>
				</div>

				<div>
					<img
						style={{ width: "10em", margin: "1em 0" }}
						src={GradPic}
						alt='Graduation day'
					/>
				</div>
				<img className='about-img' src={ChiExp} alt='Graduation day' />
			</Col>
			<Col className='sidebar-r-about' md={4} sm={12}>
				<h3>EXHIBITIONS AND AWARDS</h3>
				<span>
					<ul>
						<li className='side-li'>
							William and Elaine Small Award- Saint Louis Artists Guild, 2019.
							-2019
						</li>

						<li className='side-li'>
							Emerson Young Artists’ Showcase -Reflective Spirit Award-Nerinx
							Hall, 2019.
						</li>
						<li className='side-li'>SAIC UnderGraduate Galleries, 2023</li>
						<li className='side-li'>
							Art of Darkness Soulard Art Gallery, 2023
						</li>
						<li className='side-li'>
							Everything is a Remix, Gallery Row, 2024
						</li>
						<li className='side-li'>Window to the Soul, Art STL, 2024</li>
						<li className='side-li'>
							Pure Enjoyment Creamery Arts Center, 2024
						</li>
						<li className='side-li'>
							62nd Mid-States Art Exhibition, Evansville Museum, 2024
						</li>
					</ul>
				</span>

				<h3>BIO</h3>
				<span>
					<ul>
						<li className='side-li'>
							<p>
								Frieda Silva is a second generation Mexican-American artist born
								in Saint Louis, Missouri. She received a BFA from the School of
								the Art Institute of Chicago in 2023. An interdisciplinary
								artist, her work investigates ideas of intrinsic identity versus
								the external persona. In the context of the United States, many
								facets of her identity would have her categorized as an outlier
								in the conventional hegemony.
							</p>
							<p>
								Her artistic practice aims to bridge the gap between
								preconceived ideas and the evident actuality of herself and the
								world around her.
							</p>
							<p>
								She seeks to blend the many confusing components of the human
								experience with the lived reality of inhabiting a body, using
								various lenses to achieve this goal. She is active in the local
								arts community and participates in various group shows.
							</p>
						</li>
					</ul>
				</span>
			</Col>
		</Row>
	);
}
