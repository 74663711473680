import { useCollection } from "../../hooks/useCollection";
import { Link } from "react-router-dom";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "react-bootstrap/Image";
import ProjectFilter from "./ProjectFilter";
import { categories } from "../../data/data";
import "./Gallery.css";

export default function Paintings() {
	const { documents, error } = useCollection("projects");

	const [filter, setFilter] = useState(categories[0].value);
	const a = categories[0].value;
	const b = categories[1].value;
	const c = categories[2].value;

	const changeFilter = (newFilter) => {
		setFilter(newFilter);
	};

	const projects = documents
		? documents.filter((document) => {
				switch (filter) {
					case a:
					case b:
					case c:
						return document.category === filter;
					default:
						return true;
				}
		  })
		: null;

	return (
		<div>
			{projects && <ProjectFilter changeFilter={changeFilter} />}
			<h2>{filter}</h2>
			<div className='gallery-content'>
				<Row style={{ width: "95%", justifyContent: "center" }}>
					{projects.map((project) => (
						<Col key={project.id} xs={6} sm={6} md={4} lg={3}>
							<div className='gallery-div'>
								<Link
									className='gallery-div-img'
									state={project}
									to={`/gallery/${project.id}`}
								>
									<Img className='gall-img' src={project.photoURL} />
								</Link>
								<h5 className='gall-img-title'>{project.name}</h5>
							</div>
						</Col>
					))}
				</Row>
			</div>
			{error && <p className='error'>{error}</p>}
		</div>
	);
}
